import axios from 'axios';
import { fetchData } from '../fetch';
// axios.defaults.baseURL = 'http://localhost:3333/api/';
axios.defaults.baseURL = 'https://api.crazzzyhall.com/api/';

export async function getDataAxios(dataName) {
  try {
    const response = await axios.get(`${dataName}`);
    return response.data;
  } catch (error) {
    return fetchData(dataName);
  }
}
