import { hideLoader } from '/src/components/loader/loader';
import { showNotification } from '/src/components/notification/notification';
import { useSessionStorage } from '/src/helpers';

window.addEventListener('load', () => {
  window.scrollTo(0, 0);
  hideLoader();
  const isNotif = useSessionStorage("chnotif");
  if (isNotif) return;
  showNotification();
});
