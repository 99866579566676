import { hideElement, showElement } from '/src/common/js';
import { useSessionStorage } from '/src/helpers';

const bodyRef = document.querySelector('body');
const notificationRef = document.querySelector('[data-notification]');
const notificationBtnRef = notificationRef?.querySelector(".notification__btn");
const notificationCloseBtnRef = notificationRef?.querySelector("[data-notification-btn='close']");

notificationBtnRef?.addEventListener("click", hideNotification);
notificationCloseBtnRef?.addEventListener("click", hideNotification);

export function showNotification () {
  showElement(notificationRef);
  bodyRef?.classList?.add("freeze");
};

export function hideNotification () {
  hideElement(notificationRef);
  bodyRef?.classList?.remove("freeze");
  useSessionStorage("chnotif", "set", true);
};
