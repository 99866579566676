import { getDataAxios } from '/src/services/axios';
import { createListMarkup, renderMarkup, useSessionStorage } from '../data';
import { fetchData } from '/src/services/fetch';

export async function fetchAndRender(elemRef, createItemMarkupFn, key) {
  if (!elemRef) return;

  let dataToRender = useSessionStorage(key);
  if (!dataToRender) {
    dataToRender = await getDataAxios(key);
    useSessionStorage(key, 'set', dataToRender);
  }

  const listMarkup = createListMarkup(dataToRender, createItemMarkupFn);
  renderMarkup(elemRef, listMarkup);
}

export async function fetchAndRenderLocal(elemRef, createItemMarkupFn, key) {
  if (!elemRef) return;

  let dataToRender = useSessionStorage(key);
  if (!dataToRender) {
    dataToRender = await fetchData(key);
    useSessionStorage(key, 'set', dataToRender);
  }

  const listMarkup = createListMarkup(dataToRender, createItemMarkupFn);
  renderMarkup(elemRef, listMarkup);
}
